<template>
    <div class="filter-wrapper">
        <div class="select-class">
            <span class="label"></span>
            <el-input
                v-model="queryList.observeTitle"
                clearable
                placeholder="观察标题"
                style="width: 170px;"
                @keyup.native.enter="inquiry"
            />
        </div>
        <div class="select-class">
            <span class="label"></span>
            <ElSelectTree
                placeholder="部门名称"
                v-model="queryList.departmentName"
                clearable
                :data="departmentList"
                :props="{
                        value: 'id',
                        label: (data) => data.name,
                        children: 'child'
                    }"
                filterable
                check-strictly
                style="width: 170px;"
                @keyup.native.enter="inquiry"
            />
        </div>
        <div class="select-class">
            <!-- supervisingTeacherList -->
            <span class="label"></span>
            <!-- <ElSelectTree
                v-model="queryList.supervisingTeacher"
                clearable
                :data="supervisingTeacherList"
                :props="{
                    value: 'id',
                    label: (data) => data.name,
                    children: 'children'
                }"
                filterable
                style="width: 400px;"
            /> -->
            <select-tree
                placeholder="督导教师"
                v-model="queryList.supervisingTeacher"
                style="width: 170px"
                :data="supervisingTeacherList"
                :props="{
                    value: 'id',
                    label: (data) => data.name,
                    children: 'children'
                }"
                @keyup.native.enter="inquiry"
            >
            </select-tree>
        </div>
        <div class="select-class">
            <span class="label"></span><ElSelectTree
                placeholder="年级"
                v-model="queryList.grade"
                clearable
                :data="gradeList"
                :props="{
                        value: 'id',
                        label: (data) => data.name,
                        children: 'child'
                    }"
                filterable
                style="width: 170px;"
                @keyup.native.enter="inquiry"
            />
        </div>
        <div class="select-class">
            <span class="label"></span>
            <ElSelectTree
                placeholder="班级"
                v-model="queryList.class"
                clearable
                :data="classList"
                :props="{
                    value: 'id',
                    label: (data) => data.name,
                    children: 'child'
                }"
                filterable
                style="width: 170px;"
                @keyup.native.enter="inquiry"
            />
        </div>
        <div class="select-class">
            <span class="label"></span>
            <el-select
                v-model="queryList.course"
                clearable
                filterable
                placeholder="课程"
                style="width: 170px;"
                @keyup.native.enter="inquiry">
                <el-option
                    v-for="item in courseList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
            </el-select>
        </div>
        <div class="select-class">
            <span class="label"></span><el-select
                v-model="queryList.status"
                clearable
                placeholder="状态"
                style="width: 170px;"
                @keyup.native.enter="inquiry">
                <el-option
                    v-for="item in statusList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
        </div>
        <div class="select-class">
            <span class="label"></span>
            <el-input
                v-model="queryList.submitted"
                clearable
                placeholder="提交人"
                style="width: 170px;"
                @keyup.native.enter="inquiry"
            />
        </div>
        <div class="select-class">
            <span class="label"></span>
            <el-date-picker
                v-model="queryList.day"
                :clearable="false"
                type="datetimerange"
                range-separator="至"
                start-placeholder="观察开始日期"
                end-placeholder="观察结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                style="width: 360px;"
            />
        </div>
        <div class="option-btn">
            <el-button style="min-width: 72px;min-height: 36px;" type="primary" @click="inquiry" v-hasPermi="['classroomObservationRecords:list']">查询</el-button>
            <el-button style="min-width: 72px;min-height: 36px;" type="primary" @click="reset">重置</el-button>
        </div>
    </div>
</template>
<script>

    import Vue from 'vue';
    import virtualList from 'vue-virtual-scroll-list'
    import ElOptionNode from './VirtulaList/ElOptionNode.vue';
    import ElSelectTree from 'el-select-tree';
    Vue.use(ElSelectTree);

    import SelectTree from '../TeachingResearchCourseScheduleTemp/Sub/SelectTree.vue'
    export default {
        name: "FilterWrapper",
        components: {
            ElSelectTree,
            'virtual-list': virtualList,
            SelectTree
        },
        props: {
            queryList: Object,
            supervisingTeacherList: Array,
            departmentList: Array,
            classList: Array,
            gradeList: Array,
            courseList: Array,
            statusList: Array
        },
        data () {
            return {
                itemComponent: ElOptionNode,
            }
        },
        methods: {
            inquiry () {
                this.$emit('inquiry')
            },
            reset () {
                this.$emit('reset')
            }
        }
    }
</script>

<style lang="scss" scoped>
    .filter-wrapper {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 20px 20px 0;
        .select-class {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            margin-right: 10px;
            .label {
                width: 10px;
                text-align: right;
                box-sizing: border-box;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #878787;

            }
        }
        .option-btn {
            margin-left: 10px;
        }
    }
    .el-date-editor /deep/ input {
        width: 120px;
    }

</style>
