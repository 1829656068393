<template>
  <div class="common-form">
    <div v-if="!errorShow" class="common-form-layout">
        <!-- 标题-添加巡课记录 -->
        <global-page-back
            :detailPageTitle="title"
            @handlerGlobalType="handleBack"
        >
        </global-page-back>
        <div class="common-form-wrapper content-bg">
            <div class="inspect-content">
                <based-info ref="BasedInfo" @backList="goBackList" v-if="tourDetails.type == 'add'"></based-info>
                <record-details v-else-if="tourDetails.type == 'details'" :recordId="tourDetails.id"></record-details>
            </div>
        </div>
    </div>
    <error v-else :errorShow="errorShow"></error>
  </div>
</template>

<script>
import { Error } from 'common-local';
import FormSubmitBtn from "../Sub/FormSubmitBtn/index.vue";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
import BasedInfo from "./inspectList/BasedInfo.vue"
import RecordDetails from "./inspectList/RecordDetails.vue"
export default {
    name:'AddObservationRecordsPatrolView',
    components: {
        Error,
        GlobalPageBack,
        FormSubmitBtn,
        BasedInfo,
        RecordDetails
    },
    props: {
        tourDetails: Object
    },
    mounted(){
        if(this.tourDetails.type == 'add'){
            this.title = '添加巡课记录'
        }else if(this.tourDetails.type == 'details'){
            this.title = '巡课记录详情'
        }
    },
    data(){
        return{
            title:'',
            errorShow: false,
        }
    },
    methods:{
        /**
         * @Description: 返回上一页
         * @Author: mrz
         * @Date: 2024-08-23 14:39:36
         */
        handleBack() {
            if(this.tourDetails.type == 'add'){
                const childBasedData = this.$refs.BasedInfo.basedFormData
                if(childBasedData.programmeId){
                    this.$confirm('返回后，填写的内容将不保留，是否继续', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(res => {
                        this.$emit('classPatrolBack')
                    }).catch(()=>{})
                }else{
                    this.$emit('classPatrolBack')
                }
            }else{
                this.$emit('classPatrolBack')
            }
        },
        /**
         * @Description: 新增页面提交成功后直接返回列表页
         * @Author: mrz
         * @Date: 2024-09-06 17:09:54
         */
         goBackList(){
            this.$emit('classPatrolBack')
         }
    }
}
</script>

<style lang="scss" scoped>
.common-form-wrapper{
    display: flex;
    justify-content: center;
}
.inspect-content{
    margin-top: 10px;
    width: 1130px;
    overflow-x: auto;
    background-color: #fff;
}
</style>
